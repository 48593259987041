@import 'legacy/stylesheets/helpers/_variables.scss';

.campaigns_update-form {
  .input {
    margin: 0;
  }
  label {
    display: inherit;
  }
}

.landing-form__title {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6rem;
}
.landing-form__first-title {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 500;
}

a:hover {
  text-decoration: none;
}

.homepage-container {
  max-width: 1170px;
  margin: auto;

  &.homepage-container--700 {
    max-width: 700px;
  }
  &.homepage-container--800 {
    max-width: 800px;
  }
  &.homepage-container--850 {
    max-width: 850px;
  }
}

.public-footer-container {
  margin: auto;
  @media screen and (min-width: 768px) {
    max-width: 768px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 1024px;
  }
  @media screen and (min-width: 1440px) {
    max-width: 1440px;
  }
}

.no-navigation-header {
  display: none;
}

.white--soft {
  background-color: var(--background-color);
}

.homepage-network__container {
  padding-top: $header_height;
  touch-action: pan-y;
  font-size: 1rem;
  flex: 1;
  position: relative;
  z-index: 1;

  &--more-padding {
    padding-top: $header_height + $header_socials_height;
  }

  &--no-padding {
    padding-top: 0 !important;
  }

  @include media-query(palm) {
    padding-top: ($header_height + $header_mobile_padding) !important;

    &--no-padding {
      padding-top: 0 !important;
    }
  }
}

.homepage__cover {
  height: 700px;
}
.homepage__cover--big {
  height: 100%;
}
.homepage__cover--fixed {
  position: fixed;
}
.homepage__cover--fixed-top {
  top: 0;
}
.homepage__cover--fixed-bottom {
  bottom: 0;
}
.homepage__cover-title {
  z-index: 9;
  height: 635px;
  margin-bottom: 0;

  @include media-query(palm) {
    height: calc(81vh);
  }

  .homepage-container {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);

    @include media-query(desk) {
      min-width: 800px;
    }
  }
}
.headings--reset {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    color: #2f363b;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: subpixel-antialiased !important;
  }

  h1 {
    @extend .h1 !optional;
  }

  h2 {
    @extend .h2 !optional;
  }

  h3 {
    @extend .h3 !optional;
  }

  h4 {
    @extend .h4 !optional;
  }

  h5 {
    @extend .h5 !optional;
  }

  h6 {
    @extend .h6 !optional;
  }
}

.homepage-network__container .homepage-container {
  @include media-query(palm) {
    & > p,
    & > p * {
      font-size: 19px !important;
    }
  }
}

.container--right {
  @include media-query(palm) {
    margin-top: 50px;
  }
}

// HEADER video && slider
.homepage__header-video,
.homepage__header-sider {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-0%) translateY(-0%);

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
  }
}
//!

// Partners
.partners {
  background-size: cover;
  background-position: center;

  .section-title {
    margin-bottom: 0;

    h1 {
      margin-bottom: 0;
    }
  }
}
.partner__item {
  min-width: 350px;
  margin-top: 35px;
}
.partner__item img {
  max-height: 120px;
  max-width: 150px;
}
//!

@include media-query(palm) {
  .login-form {
    width: 95% !important;
  }
}
.login-form {
  width: 400px;
  margin: auto;
  font-size: 1rem;
  line-height: 1rem;

  @extend .white-box-with-shadow;
}
.login-form__title {
  line-height: 1.3rem;
  margin-bottom: 30px;
}
.login-form__social-connect {
  width: 20%;
  display: inline-block;

  &:first-of-type {
    margin-bottom: 0 !important;
  }

  @extend .soft-quarter--bottom;
}
.login-form__container {
  &.full-height-minus-padding {
    height: calc(100vh - 65px);
    min-height: calc(100vh - 65px);
    padding: 30px 0;
  }
  &.full-height-minus-more-padding {
    height: calc(100vh - 95px);
    min-height: calc(100vh - 95px);
    padding: 30px 0;
  }
}

#approval_form,
#renew_password {
  font-size: 1rem;
  line-height: 1.25rem;
}

#breadcrumbs-signup-payments-memberships-v2 {
  margin-bottom: 32px;
  background-color: transparent;

  @media screen and (min-width: 768px) {
    padding-top: 80px;
  }
}

// Text editor link styles are overriden in the user sso not found view
// https://hivebrite.atlassian.net/browse/PS-10961
.sso_user_not_found {
  a {
    color: $link-color;
    text-decoration: underline;
  }
}

.public-network-form,
.confirm-form {
  margin: 200px 0 100px 0;
  position: relative;
  z-index: 10;

  &.with-signup-payment-rebuild-bridge {
    margin: 0 auto 100px auto;
    border-radius: 0;

    > div.soft-double {
      padding: 24px !important;
    }

    @media screen and (min-width: 768px) {
      margin-left: 20px;
      margin-right: 20px;
    }

    @media screen and (max-width: 768px) {
      margin-left: 20px;
      margin-right: 20px;
    }

    @media screen and (min-width: 1024px) {
      width: calc(83.33333333333334% - 42px);
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (min-width: 1440px) {
      width: calc(66.6% - 38px);
    }
  }

  @extend .bg-white;
  @extend .rounded;

  label {
    @extend .label--block;
  }

  @include media-query(palm) {
    margin-top: 120px;
  }

  select {
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    min-height: 38px !important;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: inherit;
    }
  }
}

.reveal-modal-bg {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: map-get($zIndices, "backdrop");
}
.reveal-modal {
  border: none;
  box-shadow: none;
  outline: 0;
  border-radius: 2px;
  max-height: initial;
  height: 80vh;
  overflow-y: scroll;
}
