.checkbox--rounded {
  label {
    position: relative;
    height: 21px;
    width: 21px;
    display: inline-block !important;
    background-color: white;
    border: 2px solid $blue;
    border-radius: 50%;
    cursor: pointer;
  }

  label:after {
    content: '';
    display: block;
    position: absolute;
    height: 11px;
    width: 11px;
    left: 3px;
    top: 3px;
    opacity: 0;
    background-color: $blue;
    border-radius: 50%;
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none !important;
    visibility: hidden;

    &:checked + label:after {
      opacity: 1;
    }
  }
}

.checkbox--squared {
  label {
    position: relative;
    height: 21px;
    width: 21px;
    display: inline-block !important;
    background-color: white;
    border: 2px solid $blue;
    cursor: pointer;
  }

  label:after {
    content: '';
    display: block;
    position: absolute;
    height: 11px;
    width: 11px;
    left: 3px;
    top: 3px;
    opacity: 0;
    background-color: $blue;
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none !important;
    visibility: hidden;

    &:checked + label:after {
      opacity: 1;
    }
  }
}
